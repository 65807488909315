import numeral from 'numeral'

export default {
  title: 'New Business Pipeline',
  key: 'new-business-pipeline',
  source: 'opportunity-advanced',
  pre_load: {},
  type: 'funnel',
  size: {
    lg: 4,
    md: 12,
    sm: 12,
  },
  config: {
    dynamic_date: true,
    totalFormat: '0.00a',
    custom_data: (data: any) => {
      let total = numeral(data.reduce((acc:number, item:any) => acc + item.amount, 0)).format('$0.00a')

      let performance = numeral(data.reduce((acc:number, item:any) => {
        if (item.status === 'won' || item.status === 'processing' || item.status === 'repeat_business' || item.status === 'consideration') {
          return acc + item.amount
        } if (item.status === 'pitched') {
          return acc + item.amount
        }
        return acc
      }, 0)).format('$0.00a')

      return `<div class="row text-center mt-3">
      <div class="col">
        <h6>Pipeline</h6>
        <h4>${performance}</h4>
      </div>
    </div>
    `
    },
  },
  capitalize_labels: true,
  dimensions: ['status'],
  metrics: ['amount'],
  order: {
    percentage: 'asc',
  },
  filters: {},
  query: ['is:new_business', 'use:pipeline_only', 'is:forecast'],
  dynamic_options: [],
  formatters: {},
  status: ['pitched', 'consideration', 'expected', 'won'],
}
