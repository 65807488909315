import Api from '@/models/Api'
import FinancialPlan from '@/models/FinancialPlan'
import SystemDashboardWidget from '@/models/SystemDashboardWidget'
import { buildColorPool } from '@/models/Util'
import { FILTERS, normalizeLabel } from '@/pages/ManagementDashboard/shared/helpers'
import { startCase } from 'lodash'
import moment from 'moment'
import numeral from 'numeral'

export default class PipelineToPlanYearWidget extends SystemDashboardWidget {
  public title: string = `Pipeline to Plan ${moment().format('YYYY')}`

  public key: string = 'revenue-to-goal-year'

  public view: string = 'chart'

  public subView: string = 'gauge'

  public dimensions: string[] = ['year']

  public metrics: string[] = ['goal_pipeline', 'goal', 'last_year_goal', 'last_year_goal_pipeline']

  public size: any = {
    lg: 4,
    md: 6,
  }

  public loaderSize: string = '350px'

  constructor(args: any = null) {
    super()

    if (args && args.dimensions) {
      this.dimensions = args.dimensions
    }

    if (args && args.metrics) {
      this.metrics = args.metrics
    }

    if (args && args.filters) {
      this.filters = Object.assign(this.filters, args.filters)
    }
    if (args && args.internalFilters) {
      this.internalFilters = Object.assign(this.internalFilters, args.internalFilters)
    }

    if (args && args.rightCol) {
      this.rightCol = args.rightCol
    }

    if (args && args.leftCol) {
      this.leftCol = args.leftCol
    }

    if (args && args.size) {
      this.size = args.size
    }

    if (args && args.title) {
      this.title = args.title
    }

    if (args && args.key) {
      this.key = args.key
    }
    if (args && args.internalQuery) {
      this.internalQuery = args.internalQuery
    }
    if (args && args.internalQueryPush) {
      this.internalQuery.push(args.internalQueryPush)
    }
  }

  public filters: any = {
    region: 'all',
    product: 'all',
  }

  public internalFilters: any = {
    year: moment().format('YYYY'),
    type: 'finance_goal',
  }

  public internalQuery: string[] = ['scope:finance_goal']

  public get filterToQuery() {
    let query = []

    if (this.filters.product !== 'all') {
      query.push(`product:${this.filters.product}`)
    }

    if (this.filters.region !== 'all') {
      query.push(`region:${this.filters.region}`)
    }

    query.push(`year:${this.internalFilters.year}`)

    return query
  }

  public get payload() {
    const query = [...new Set([...this.query, ...this.internalQuery, ...this.filterToQuery])]

    return {
      dimensions: this.dimensions,
      metrics: this.metrics,
      filters: {
        ...this.internalFilters,
      },
      query,
    }
  }

  public flattenPayload: boolean = true

  public set_label_to_percentage: boolean = true

  public async dataSource() {
    return FinancialPlan.getPlanDashboard(this.payload).then((res: any) =>
      res.data.result.sort((a: any, b: any) => b[this.metrics[0]] - a[this.metrics[0]]))
  }

  public formatters: any = {
    yaxis: (_: SystemDashboardWidget, value: any) => `${numeral(value).format('0,0')}`,
    label: (_: SystemDashboardWidget, value: any) => `${numeral(value).format('0,0')}`,
    tooltip: (_: SystemDashboardWidget, value: any) => [numeral(value).format('0,0')],
  }

  protected dataMapper(data: any): Promise<any> {
    let data_series: any[] = []
    let labels: string[] = []
    const series_raw: number[] = []
    let colors: string[] = []
    let total = 0

    const color_pool = buildColorPool(data.length, true)

    total = data.reduce((acc: number, item: any) => (acc += Number(item[this.metrics[1]])), 0)

    data.forEach((item: any, idx: number) => {
      const val = Number(item[this.metrics[0]])
      const target = Number(item[this.metrics[1]])

      data_series.push((val / target) * 100)

      series_raw.push(val)
      labels.push(item[this.dimensions[0]])
      colors.push('#323E48')
    })

    if (this.set_label_to_percentage) {
      if (!data || !data[0] || !data[0][this.metrics[1]] || !data[0][this.metrics[0]]) {
        labels = [numeral(0).format('0.0%')]
      } else {
        labels = [
          numeral(Number(data[0][this.metrics[0]]) / Number(data[0][this.metrics[1]])).format(
            '0.0%',
          ),
        ]
      }
    }

    this.chartSettings.colors = colors

    this.chartSettings.xaxis = {
      categories: labels,
    }

    this.chartSettings.labels = labels

    return Promise.resolve(data_series)
  }

  public get customData() {
    return this.gauge_custom_data(this.rawData, 'pipeline', 'year')
  }

  public gauge_custom_data = (
    data: any,
    target: string,
    period: string,
    secondary_label: string = 'Plan',
  ) => {
    if (!data.length || !data) {
      data = [
        {
          goal: 0,
          goal_pipeline: 0,
          last_year_goal: 0,
          last_year_goal_pipeline: 0,
          plan_name: '',
        },
      ]
    }
    let label = 'Sold'
    let note = ''

    if (target == 'forecast') {
      label = 'Pipeline'
      note = `<div style="font-size: x-small; bottom: 1rem; position: absolute" class="text-muted mt-5 px-2">
      * Pipeline: Repeated Business + 10% Pitched + Consideration + Expected + Processing + Sold
    </div>`
    } else if (target != 'sold') {
      label = 'Pipeline'
    }

    let last_year_goal_key = `last_year_goal_${target}`

    if (target == 'forecast') {
      last_year_goal_key = 'goal'
    }

    if (data[0].plan_name) {
      secondary_label = data[0].plan_name
    }

    // Sold Logic
    const sold = numeral(data[0][`goal_${target}`]).format('$0.00a')
    const last_year_sold = numeral(data[0][last_year_goal_key]).format('$0.00a')
    let last_year_sold_percentage: any = data[0][last_year_goal_key] == 0
      ? 1
      : (data[0][`goal_${target}`] - data[0][last_year_goal_key]) / data[0][last_year_goal_key]
    const sold_icon = last_year_sold_percentage > 0 ? '▴' : '▾'
    const sold_color = last_year_sold_percentage > 0 ? 'text-success' : 'text-danger'
    last_year_sold_percentage = numeral(last_year_sold_percentage).format('0%')

    // Goal Logic
    const goal = numeral(data[0].goal).format('$0.00a')
    const last_year_goal = numeral(data[0].last_year_goal).format('$0.00a')
    let last_year_goal_percentage: any = data[0].last_year_goal == 0
      ? 1
      : (data[0].goal - data[0].last_year_goal) / data[0].last_year_goal
    const goal_icon = last_year_goal_percentage > 0 ? '▴' : '▾'
    const goal_color = last_year_goal_percentage > 0 ? 'text-success' : 'text-danger'
    last_year_goal_percentage = numeral(last_year_goal_percentage).format('0%')

    return `<div class="row text-center mb-4">
      <div class="col">
        <h6>${label}</h6>
        <h4>${sold}</h4>
        <div class="${sold_color}" style="font-size:small;">${sold_icon} ${last_year_sold_percentage}</div>
        <div class="text-muted">*Last ${period}: ${last_year_sold}</div>
      </div>
      <div class="col">
        <h6>${secondary_label}</h6>
        <h4>${goal}</h4>
        <div class="${goal_color}" style="font-size:small;">${goal_icon} ${last_year_goal_percentage}</div>
        <div class="text-muted">*Last ${period}: ${last_year_goal}</div>
      </div>
    </div>${note}
    `
  }
}
