import numeral from 'numeral'

export default {
  title: 'Existing Business Pipeline',
  key: 'existing-business-pipeline',
  source: 'opportunity-advanced',
  type: 'funnel',
  size: {
    lg: 4,
    md: 12,
    sm: 12,
  },
  config: {
    dynamic_date: true,
    totalFormat: '0.00a',
    custom_data: (data: any) => {
      let total = numeral(data.reduce((acc: number, item: any) => acc + item.amount, 0)).format(
        '$0.00a',
      )

      let performance = numeral(
        data.reduce((acc: number, item: any) => {
          if (
            item.status === 'won'
            || item.status === 'processing'
            || item.status === 'repeat_business'
            || item.status === 'consideration'
            || item.status === 'expected'
          ) {
            return acc + item.amount
          }
          if (item.status === 'pitched') {
            return acc + item.amount * 0.1
          }
          return acc
        }, 0),
      ).format('$0.00a')

      return `<div class="row text-center mt-3">
      <div class="col">
        <h6>Pipeline</h6>
        <h4>${performance}</h4>
      </div>
    </div>
    `
    },
  },
  capitalize_labels: true,
  dimensions: ['status'],
  metrics: ['amount'],
  label: 'Revenue',
  order: {
    percentage: 'asc',
  },
  filters: {
  },
  query: ['is:returning_business', 'use:pipeline_only', 'is:forecast'],
  dynamic_options: [],
  formatters: {},
  status: ['pitched', 'consideration', 'expected', 'won'],
}
